// gatsby-browser.js
// require("prismjs/themes/prism-dark.css");
require("prismjs/themes/prism-solarizedlight.css");
// require("prismjs/plugins/line-numbers/prism-line-numbers.css");
// require("prismjs/plugins/command-line/prism-command-line.css");
require("bootstrap/dist/css/bootstrap.min.css");
require("katex/dist/katex.min.css");

exports.shouldUpdateScroll = () => {
  return false;
};
